<template>
  <el-row>
    <el-col :span="24">
      <div class="main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="概述" name="first">
            <img :src="one" alt="1" class="resize_img">
            <img :src="two" alt="2" class="resize_img" style="max-height: 1200px;">
            <img :src="three" alt="3" class="resize_img" style="max-height: 1000px;">
            <img :src="four" alt="4" class="resize_img" style="max-height: 1000px;">
            <img :src="five" alt="5" class="resize_img" style="max-height: 1400px;">
            <img :src="six" alt="6" class="resize_img" style="max-height: 1400px;">
            <img :src="seven" alt="7" class="resize_img" style="max-height: 1400px;">
            <img :src="eight" alt="8" class="resize_img" style="max-height: 1400px;">
            <img :src="night" alt="9" class="resize_img" style="max-height: 1400px;">
            <img :src="night" alt="10" class="resize_img" style="max-height: 1400px;">
            <img :src="ten" alt="11" class="resize_img" style="max-height: 1400px;">
            <img :src="ten_one" alt="12" class="resize_img" style="max-height: 1400px;">
            <img :src="ten_two" alt="13" class="resize_img" style="max-height: 1400px;">
            <div class="con_foot">
              <p style="float: left; font-size: 30px; color: #fff;">您可能还喜欢</p>
              <el-link style="float: right; font-size: 16px; color: #fff;" :underline="false" href="#">查看更多 ></el-link>
              <div class="content">
                <div class="con_item">
                  <div>
                    <img :src="one_one" alt="1" class="resize_img">
                  </div>
                  <p>CG-2手机支架</p>
                  <el-link style="font-size: 16px; color: #0088FE;" :underline="false" href="#">查看更多 ></el-link>
                </div>
                <div class="con_item">
                  <div>
                    <img :src="one_two" alt="2" class="resize_img">
                  </div>
                  <p>G3胎压监测</p>
                  <el-link style="font-size: 16px; color: #0088FE;" :underline="false" href="#">查看更多 ></el-link>
                </div>
                <div class="con_item">
                  <div>
                    <img :src="one_three" alt="3" class="resize_img">
                  </div>
                  <p>XR-3记录仪</p>
                  <el-link style="font-size: 16px; color:#0088FE;" :underline="false" href="#">查看更多 ></el-link>
                </div>
              </div>
            </div>

          </el-tab-pane>
          <el-tab-pane label="常见问题" name="second">
            <el-row>
              <el-col :span="24" style="padding: 0 25%;">
                <h1 class="title">常见问题</h1>
                <div class="info">
                  <div class="menu">
                    <el-input v-model="input" placeholder="搜索"></el-input>
                    <el-collapse v-model="activeNames" @change="handleChange">
                      <el-collapse-item title="AIO-5" name="1">
                        <div class="menu_item">
                          <el-link href="#/home/g3/one" :underline="false">OBD常见问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/g3/two" :underline="false">qq音乐问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/g3/three" :underline="false">导航常见问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/g3/four" :underline="false">手机投屏问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/g3/five" :underline="false">胎压常见问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/g3/six" :underline="false">行车记录问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/g3/seven" :underline="false">音频及手机互联问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/g3/eight" :underline="false">其它问题</el-link>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                  <div class="content">
                    <router-view />
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="规格" name="third">
            <img :src="img_top" alt="1" class="aio_img_resize" style="margin-top: 60px; max-weight: 400px; max-height: 400px;">
            <ul class="specs" style="padding: 0 25%;">
              <li>
                <span class="title">概要</span>
                <div class="info">
                  <div>
                    <p style="color: grey;">产品型号</p>
                    <p style="color: #fff;">AIO-5</p>
                  </div>
                  <div>
                    <p style="color: grey;">处理器</p>
                    <p style="color: #fff;">ARM 2.0GHZ 8核</p>
                  </div>
                  <div>
                    <p style="color: grey;">运存</p>
                    <p style="color: #fff;">3GB</p>
                  </div>
                  <div>
                    <p style="color: grey;">内存</p>
                    <p style="color: #fff;">32GB</p>
                  </div>
                  <div>
                    <p style="color: grey;">扩展</p>
                    <p style="color: #fff;">128G高速TF卡</p>
                  </div>
                  <div>
                    <p style="color: grey;">定位</p>
                    <p style="color: #fff;">北斗+GPS</p>
                  </div>
                </div>
              </li>
              <li>
                <span class="title">网络与传输</span>
                <div class="info">
                  <div>
                    <p style="color: grey;">433M无线通讯</p>
                    <p style="color: #fff;">支持</p>
                  </div>
                  <div>
                    <p style="color: grey;">移动网络</p>
                    <p style="color: #fff;">4G LTE全网通</p>
                  </div>
                  <div>
                    <p style="color: grey;">蓝牙</p>
                    <p style="color: #fff;">Bluetooth 5.0</p>
                  </div>
                  <div>
                    <p style="color: grey;">WLAN</p>
                    <p style="color: #fff;">2.4G/5G双频Wi-Fi</p>
                  </div>
                  <div>
                    <p style="color: grey;">传输速度</p>
                    <p style="color: #fff;">30MB/s</p>
                  </div>
                </div>
              </li>
              <li>
                <span class="title">屏幕</span>
                <div class="info">
                  <div>
                    <p style="color: grey;">屏幕亮度</p>
                    <p style="color: #fff;">> 800NIT</p>
                  </div>
                  <div>
                    <p style="color: grey;">屏幕规格</p>
                    <p style="color: #fff;">5寸 1280x720 IPS</p>
                  </div>
                </div>
              </li>
              <li>
                <span class="title">视频</span>
                <div class="info">
                  <div>
                    <p style="color: grey;">图像传感器</p>
                    <p style="color: #fff;">SONY IMAX307</p>
                  </div>
                  <div>
                    <p style="color: grey;">镜头</p>
                    <p style="color: #fff;">F1.8光圈 6P全玻璃片</p>
                  </div>
                  <div>
                    <p style="color: grey;">镜头角度</p>
                    <p style="color: #fff;">150° 广角</p>
                  </div>
                  <div>
                    <p style="color: grey;">是否防抖</p>
                    <p style="color: #fff;">智能防抖</p>
                  </div>
                  <div>
                    <p style="color: grey;">录制模式</p>
                    <p style="color: #fff;">前后双摄像头</p>
                  </div>
                  <div>
                    <p style="color: grey;">录制格式</p>
                    <p style="color: #fff;">1080P 30FPS</p>
                  </div>
                  <div>
                    <p style="color: grey;">视频格式</p>
                    <p style="color: #fff;">MP4</p>
                  </div>
                  <div>
                    <p style="color: grey;">昼夜模式</p>
                    <p style="color: #fff;">自动切换</p>
                  </div>
                </div>
              </li>
              <li>
                <span class="title">OBD数据</span>
                <div class="info">
                  <div>
                    <p style="color: grey;">OBD实时数据</p>
                    <p style="color: #fff;">选配</p>
                  </div>
                  <div>
                    <p style="color: grey;">OBD数据叠加</p>
                    <p style="color: #fff;">可叠加于行车录像中（选配)</p>
                  </div>
                  <div>
                    <p style="color: grey;">OBD故障诊断</p>
                    <p style="color: #fff;">选配</p>
                  </div>
                  <div>
                    <p style="color: grey;">传感器</p>
                    <p style="color: #fff;">光线、地磁、气压、陀螺仪、加速</p>
                    <p style="color: #fff; margin-top: -10px;">度、麦克风</p>
                  </div>
                  <div>
                    <p style="color: grey;">专业仪表</p>
                    <p style="color: #fff;">可自定义</p>
                  </div>
                  <div>
                    <p style="color: grey;">远程防盗</p>
                    <p style="color: #fff;">高音报警喇叭、摄像头即时
                      拍照回传</p>
                  </div>
                  <div>
                    <p style="color: grey;">远程服务</p>
                    <p style="color: #fff;">支持远程APP监测</p>
                  </div>
                  <div>
                    <p style="color: grey;">保养提醒</p>
                    <p style="color: #fff;">APP支持</p>
                  </div>
                </div>
              </li>
              <li>
                <span class="title">OBD数据</span>
                <div class="info">
                  <div>
                    <p style="color: grey;">防震结构</p>
                    <p style="color: #fff;">钛铝合金外壳</p>
                  </div>
                  <div>
                    <p style="color: grey;">低压保护电压</p>
                    <p style="color: #fff;">12V</p>
                  </div>
                  <div>
                    <p style="color: grey;">高低温</p>
                    <p style="color: #fff;">高温70℃ / 低温-25℃</p>
                  </div>
                  <div>
                    <p style="color: grey;">减震装置</p>
                    <p style="color: #fff;">三轴悬浮减震</p>
                  </div>
                </div>
              </li>
              <li>
                <span class="title">包装清单</span>
                <div class="info">
                  <div>
                    <p style="color: #fff;">AIO-5 主机 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">摄像头 x2</p>
                  </div>
                  <div>
                    <p style="color: #fff;">T10&T25梅花扳手 x2</p>
                  </div>
                  <div>
                    <p style="color: #fff;">球头座 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">并线扣 x3</p>
                  </div>
                  <div>
                    <p style="color: #fff;">贴纸 x2</p>
                  </div>
                  <div>
                    <p style="color: #fff;">尼龙扎带 x10</p>
                  </div>
                  <div>
                    <p style="color: #fff;">魔术贴束线带 x4</p>
                  </div>
                  <div>
                    <p style="color: #fff;">车机线束 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">摄像头线束 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">说明书 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">管夹变径套 x3</p>
                  </div>
                  <div>
                    <p style="color: #fff;">固定管夹 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">4G物联卡 x1</p>
                  </div>
                </div>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="购买" name="fourth">

          </el-tab-pane>
        </el-tabs>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      input: '',
      activeName: 'first',
      activeNames: '',
      one: require('../../../public/images/G3/1.png'),
      two: require('../../../public/images/G3/2.png'),
      three: require('../../../public/images/G3/3.png'),
      four: require('../../../public/images/G3/4.png'),
      five: require('../../../public/images/G3/5.png'),
      six: require('../../../public/images/G3/6.png'),
      seven: require('../../../public/images/G3/7.png'),
      eight: require('../../../public/images/G3/8.png'),
      night: require('../../../public/images/G3/9.png'),
      ten: require('../../../public/images/G3/10.png'),
      ten_one: require('../../../public/images/G3/11.png'),
      ten_two: require('../../../public/images/G3/12.png'),
      ten_three: require('../../../public/images/G3/13.png'),
      one_one: require('../../../public/images/aio5/1-1.png'),
      one_two: require('../../../public/images/aio5/1-2.png'),
      one_three: require('../../../public/images/aio5/1-3.png'),
      img_top: require('../../../public/images/aioproduct/specs/44780f47211a8f8661af2783d584bf3@2x.png')
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    handleChange() {
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  background: #000;
}
// tab分栏
::v-deep .el-tabs__nav {
  // float: right;
  // margin-right: 10%;
  left: 55% !important;
  // transform: translateX(-30%);
}
::v-deep .el-tabs__nav-wrap::after {
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  background-color: rgba(255, 255, 255, 0.3);
}
::v-deep .el-tabs__nav-wrap::before {
  content: 'G3';
  display: block;
  position: absolute;
  top: 7px;
  left: 25%;
  color: #fff;
}
::v-deep .el-tabs__item {
  color: rgba(255, 255, 255, 0.3);
}
::v-deep .el-tabs__item.is-active {
  color: #fff;
}
::v-deep .el-tabs__item:hover {
  color: #fff;
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.resize_img {
  width: 100%;
  height: auto;
  max-height: 800px;
  margin-top: -12px;
}
.con_foot {
  width: 100%;
  height: 850px;
  padding: 100px 20%;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    .con_item {
      width: 30%;
      height: 450px;
      background: #fff;
      p {
        font-size: 20px;
        color: #111;
        line-height: 60px;
      }
      .el-link {
        line-height: 60px;
      }
      div {
        height: 300px;
        line-height: 60px;
        padding: 40px;
      }
    }
  }
}
.specs li {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  text-align: left;
  span {
    position: relative;
    top: 15px;
    left: 20px;
    font-weight: 500;
    font-size: 30px;
    color: #fff;
  }
  .info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 30%;
    width: calc(100% - 200px);
    // height: 400px;
    // background-color: purple;
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      height: 100px;
      // background-color: pink;
      text-align: left;
      p {
        line-height: 40px;
      }
    }
  }
}
.el-col {
  background-color: #f8f8f8;
}
.navbar {
  height: 40px;
  padding: 15px 25% 0;
  background: #dfdfdf;
}
.resize {
  width: 100%;
  height: 100%;
}
.title {
  margin-top: 80px;
  height: 60px;
  line-height: 60px;
  text-align: left;
  // border-bottom: 1px solid #dfdfdf;
  text-indent: 1em;
}
.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  .menu {
    flex: 1;
    // height: 300px;
    border-right: 1px solid #dfdfdf;
    ::v-deep .el-input__inner {
      width: 95%;
      background-color: #ebebeb;
      border: none;
      margin-top: 40px;
    }
    ::v-deep .el-collapse-item__header {
      font-size: 16px;
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__wrap {
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__content {
      padding: 0;
    }
    .menu_item {
      height: 40px;
      line-height: 40px;
      text-indent: 1em;
    }
    .menu_item:hover {
      background-color: #ebebeb;
      border-left: 2px solid #0088fe;
    }
  }
  .content {
    flex: 3;
  }
}
</style>